import React from "react"
import StructurePagePodcast from "@components/pageRecursos/template"
import { Helmet } from "react-helmet"


const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Podcast: Digitalízate con Beex [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/podcast/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Escucha todos los episodios de nuestro podcast enfocados en atención al cliente, telemarketing, omnicanalidad y mucho más."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Podcast: Digitalízate con Beex"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/podcast/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Escucha todos los episodios de nuestro podcast enfocados en atención al cliente, telemarketing, omnicanalidad y mucho más."
      />
    </Helmet>
    <StructurePagePodcast location={location} recurso="podcast"/>
  </div>
)

export default IndexPage
